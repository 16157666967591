import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Layout from '../components/layout/layout'

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/siteSettings/" }) {
			title
			siteLogo {
        asset {
          _id
        }
      }
    }
    companyInfo: sanityCompanyInfo(_id: { regex: "/companyInfo/" }) {
      name
      address1
      address2
      zipCode
      city
			country
			socialMedia
    }
  }
`

function LayoutContainer (props) {
  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data || !data.site) {
          throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data'
          )
        }
        if (!data.companyInfo) {
          throw new Error(
            'Missing "Company info". Open the studio at http://localhost:3333 and add "Company info" data'
          )
        }
        return (
          <Layout
            {...props}
            companyInfo={data.companyInfo}
						siteTitle={data.site.title}
						siteLogo={data.site.siteLogo}
						siteRoutes={[{routeTitle: "About", routeName: "About Us", routeUrl: "#about-us"}, {routeTitle: "Projects", routeName: "Projects", routeUrl: "#projects"}, {routeTitle: "Contact", routeName: "Contact Us", routeUrl: "#contact-us"}]}
          />
        )
      }}
    />
  )
}

export default LayoutContainer
