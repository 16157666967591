import React from 'react'
import { buildImageObj } from '../../../lib/helpers'
import { imageUrlFor } from '../../../lib/image-url'
// reactstrap components
import {
  NavItem,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import AnchorLink from 'react-anchor-link-smooth-scroll'

import styles from './footer.module.css'

const routingLinks = [{
      copy: "About",
      path: "/about/"
    },
    {
      copy: "Projects",
      path: "/projects/"
    },
    {
      copy: "Blog",
      path: "/blog/"
    },
    {
      copy: "Contact",
      path: "/contact/"
    },
];
const chunk = (arr, size) => {
 return Array.from({
      length: Math.ceil(arr.length / size)
    }, (v, i) =>
    arr.slice(i * size, i * size + size)
    )
  }
  
const Footer = ({companyInfo, siteLogo, siteRoutes}) => {
  
  return (
    <footer className={"footer"}>
      <Container>
        <Row>
          <Col md={"3"}>
            {companyInfo && (
              <div>
								<img style={{borderRadius: 0, height: 30}} src={imageUrlFor(buildImageObj(siteLogo)).height(30).url()}/>
                <br />
                {companyInfo.address1}
                <br />
                {companyInfo.address2 && (
                  <span>
                    {companyInfo.address2}
                    <br />
                  </span>
								)}
								{companyInfo.city}{companyInfo.country && <span>, {companyInfo.country}</span>} {companyInfo.zipCode}
              </div>
            )}
          </Col>
              {
                chunk(siteRoutes, 4).map((chunk, index, array) => (
                <Col key={Math.random(10)} md={((12 / array.length) - 3)}>
                  <Nav>
                    {
                      chunk.map(({routeTitle, routeUrl}) => (
                        <NavItem key={Math.random(10)}>
                          <div className={'nav-link'}>
														<AnchorLink href={routeUrl} offset={70}>
															{routeTitle}
														</AnchorLink>
                          </div>
                        </NavItem>
                      ))
                    }
                  </Nav>
                </Col>
                ))
              }
        </Row>
        <Row>
          <Col md={"12"} style={{textAlign: "center", paddingTop: 50, paddingBottom: 10}}>
            <div>
                © {new Date().getFullYear()}, Built by <a href='https://www.polyverselabs.com'>Polyverse Labs</a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer;
