import { format } from 'date-fns'

export function cn (...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes (data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs ({ slug }) {
  return (slug || {}).current
}

export function getBlogUrl (publishedAt, slug) {
  return `/blog/${format(publishedAt, 'YYYY/MM')}/${slug.current || slug}/`
}

export function buildImageObj (source) {
	const imageObj = {
		asset: { _ref: 
			Object.keys(source.asset).length > 0 && source.asset._ref ? source.asset._ref : source.asset._id 
		}
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export const chunkArray = (array, chunkSize) => {
	if (chunkSize > 1) {
		var array2 = array.slice(0),
				arrays = [];
	
		while (array2.length > 0) {
				arrays.push(array2.splice(0, chunkSize));
		}
	
		return arrays;
	} else {
		return array;
	}
}
