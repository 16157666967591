import React, { useEffect , useState} from 'react'
import LogoFacebook from 'react-ionicons/lib/LogoFacebook'
import LogoTwitter from 'react-ionicons/lib/LogoTwitter'
import LogoInstagram from 'react-ionicons/lib/LogoInstagram'
import IosCloseCircleOutline from 'react-ionicons/lib/IosCloseCircleOutline'
import { buildImageObj } from '../../../lib/helpers'
import { imageUrlFor } from '../../../lib/image-url'
import AnchorLink from 'react-anchor-link-smooth-scroll'
 
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";


const NavBar = ({ companyInfo, siteLogo, routes, showNav, toggleNav, ...etc }) => {
	// const [collapseOpen, setCollapseOpen] = useState(showNav)
	const [color, setColor] = useState("navbar-transparent")
	const [collapseOut, setCollapseOut] = useState("")

	const iconColor = "#ffffff"
	const changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
			setColor("bg-brand")
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
			setColor("navbar-transparent")
    }
	};
	
	const toggleCollapse = () => {
		// document.documentElement.classList.toggle("nav-open");
		// setCollapseOpen(!collapseOpen)
		toggleNav()
	};
	
	useEffect(() => {
    window.addEventListener("scroll", changeColor);
		return () => {
			window.removeEventListener("scroll", changeColor);
		};
	}, [])

	const socialMedia = (link) => {
		switch (true) {
			case (/instagram\.com/g).test(link):
				return {
							link: link,
							icon:  LogoInstagram,
							copy: "Instagram",
							title: "Follow us on Instagram"
						}
			case (/facebook\.com/g).test(link):
				return {
							link: link,
							icon:  LogoFacebook,
							copy: "Facebook",
							title: "Follow us on Facebook"
						}
			case (/twitter\.com/g).test(link):
				return {
							link: link,
							icon:  LogoTwitter,
							copy: "Twitter",
							title: "Follow us on Twitter"
						}
			default:
				break;
		}
	}

	return (
		<Navbar
			className={"fixed-top " + color}
			color-on-scroll="100"
			expand="lg"
		>
			<Container>
				<div className="navbar-translate">
					<AnchorLink href={"#banner"} offset={70}>
						<NavbarBrand
							tag={'div'}
							data-placement="bottom"
							rel="noopener noreferrer"
							title={companyInfo && (companyInfo.name)}
						>
							<img style={{borderRadius: 0, height: 30}} src={imageUrlFor(buildImageObj(siteLogo)).height(30).url()}/>
						</NavbarBrand>
					</AnchorLink>
					<button
						aria-expanded={showNav}
						className="navbar-toggler navbar-toggler"
						onClick={toggleCollapse}
					>
						<span className="navbar-toggler-bar bar1" />
						<span className="navbar-toggler-bar bar2" />
						<span className="navbar-toggler-bar bar3" />
					</button>
				</div>
				<Collapse
					className={"justify-content-end " + collapseOut}
					navbar
					isOpen={showNav}
					onExiting={() => setCollapseOut("collapsing-out")}
					onExited={() => setCollapseOut("")}
				>
					<div className="navbar-collapse-header">
						<Row>
							<Col className="collapse-brand" xs="6">
								<NavLink
									data-placement="bottom"
									href={"/"}
									rel="noopener noreferrer"
									title={companyInfo && (companyInfo.name)}
								>
									<img style={{borderRadius: 0, height: 30}} src={imageUrlFor(buildImageObj(siteLogo)).height(30).url()}/>
								</NavLink>
							</Col>
							<Col className="collapse-close text-right" xs="6">
								<button
									aria-expanded={showNav}
									className="navbar-toggler"
									onClick={toggleCollapse}
								>
									<IosCloseCircleOutline color={iconColor} fontSize={"32px"} />
								</button>
							</Col>
						</Row>
					</div>
					<Nav navbar>
						{
							companyInfo.socialMedia && companyInfo.socialMedia.length > 0 ?
							companyInfo.socialMedia.map((social) => {
								const {icon, link, copy, title} = socialMedia(social);
								const Icon = icon;
								return(
									<NavItem className="p-0" key={Math.random(10)}>
										<NavLink
											data-placement="bottom"
											href={link}
											rel="noopener noreferrer"
											target="_blank"
											title={title}
										>
											<Icon color={iconColor}/>
											<p className="d-lg-none d-xl-none">{copy}</p>
										</NavLink>
									</NavItem>
								)
							}) : null
						}
						{
							routes && routes.length > 0 ?
							routes.map(({routeTitle, routeUrl}) => {
								return(
									<NavItem key={Math.random(10)}>
										<div className={'nav-link'}>
											<AnchorLink href={routeUrl} offset={70}>
												{routeTitle}
											</AnchorLink>
										</div>
									</NavItem>
								)
							}) : null
						}
					</Nav>
				</Collapse>
			</Container>
		</Navbar>
	)
}

export default NavBar
