import React, { Component } from 'react'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import IosArrowDropleftCircle from 'react-ionicons/lib/IosArrowDropleftCircle'
import IosArrowDroprightCircle from 'react-ionicons/lib/IosArrowDroprightCircle'
import { chunkArray } from '../../lib/helpers'

import {
	UncontrolledCarousel,
	Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

const SlideShowUI = (props) => (
	<div className={'slide-show'}
		style={{
			position: 'relative',
			zIndex: 1,
			paddingTop: 35,
			paddingBottom: 35
		}}
	>
		<div className={'slide-show--controls'}
			style={{
				position: 'absolute',
				top: '0',
				zIndex: '2',
				width: '100%',
				height: '100%',
				display: 'flex',
				flexFlow: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: '10px',
			}}
		>
			<IosArrowDropleftCircle fontSize={'32px'} style={{fill: 'white'}} onClick={props.previousCallback}/>
			<IosArrowDroprightCircle fontSize={'32px'} style={{fill: 'white'}} onClick={props.nextCallback}/>
		</div>
		<UncontrolledCarousel 
			items={props.items}  
			controls={false} 
			indicators 
			activeIndex={props.activeIndex}
			next={props.nextCallback}
			previous={props.previousCallback}
			/>
	</div>
)

const GalleryUI = (props) => {
	const rows = chunkArray(props.items, 3);
	return(
		<div 
			className={'gallery'}
			style={{
				paddingTop: 35,
				paddingBottom: 35
			}}
		>
			{
				rows.map((row, index, array) => {
					if(array.length > 1) {
						return(
							<Row>
								{
									row.map((item, itemIndex, itemArray) => (
										<Col md={`${12 / itemArray.length}`}>
											<Card
												className="card-blog card-background"
												data-animation="zooming"
												style={{
													maxHeight: 400,
													minHeight: 400
												}}
											>
												<div
													className="full-background"
													style={{
														backgroundImage: `url(${item.src})`,
													}}
												/>
											</Card>
										</Col>
									))
								}
							</Row>
						)
					}
				})
			}
		</div>
	)
}

class Slideshow extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      activeIndex: 0,
      items: this.props.slides.map((slide, index) => ({
        src: imageUrlFor(buildImageObj(slide))
          .width(this.props.slideShowBool ? 400 : 1200)
          .height(this.props.slideShowBool ? 400 : (Math.floor((9 / 16) * 1200)))
          .fit('crop')
          .url(),
        altText: `Slide ${ index + 1}`
      }))
    };
  }

    onExiting = () => {
      this.animating = true;
    }

    onExited = () => {
      this.animating = false;
    }

    next = () => {
      if (this.animating) return;
      const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
      this.setState({
        activeIndex: nextIndex
      });
    }

    previous = () => {
      if (this.animating) return;
      const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
      this.setState({
        activeIndex: nextIndex
      });
    }

  render () {
    if (!this.props.slides) return null
		const { activeIndex, items } = this.state;
		if(this.props.slideShowBool) {
			return(
				<GalleryUI
					items={items}
				/>
			)
		} else {
			return (
				<SlideShowUI
					previousCallback={this.previous}
					nextCallback={this.next}
					activeIndex={activeIndex} 
					items={items}
				/>
			)
		}
  }
}

export default Slideshow
