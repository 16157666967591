import PortableText from '@sanity/block-content-to-react'
import React, { Fragment } from 'react'
import Figure from './figure'
import Slideshow from './slideshow'
import VideoEmbedPreviewUrl from '../video-embed-url/video-embed-url'

const BlockContent = ({ blocks, style, fullRow, pStyle }) => {
	const serializers = {
		types: {
			block (props) {
				switch (props.node.style) {
					case 'h1':
						return <h1 { ...(style ? {style: style} : {})}>{props.children}</h1>
	
					case 'h2':
						return <h2 { ...(style ? {style: style} : {})}>{props.children}</h2>
	
					case 'h3':
						return <h3 { ...(style ? {style: style} : {})}>{props.children}</h3>
	
					case 'h4':
						return <h4 { ...(style ? {style: style} : {})}>{props.children}</h4>
	
					case 'blockquote':
						return <Fragment><br/><blockquote { ...(style ? { ...{style: style}, ...{fontStyle: 'italic'}} : {fontStyle: 'italic'})}>“{props.children}”</blockquote></Fragment>
	
					case 'cite':
						return <Fragment><cite class="cite" { ...(style ? {style: style} : {})}><small class="small">{props.children}</small></cite><br /><br /></Fragment>
	
					default:
						return <p { ...(style ? { ...{style: style}, ...(pStyle ? {style: pStyle} : {})} : {style: pStyle})} >{props.children}</p>
				}
			},
			embed (props) {
				return <Fragment><br/><VideoEmbedPreviewUrl value={{url: props.node.embedUrl}}/></Fragment>
			},
			figure (props) {
				return <Figure {...props.node} fullRow/>
			},
			slideshow (props) {
				return <Slideshow {...props.node} />
			}
		}
	}
	
	return(
		<PortableText blocks={blocks} serializers={serializers} />
	)
}

export default BlockContent
