import React, { Fragment } from 'react'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import {
  Row
} from "reactstrap";
function Figure (props) {
  return (
		<Fragment>
			{
				props.fullRow ?
				<Row 
					style={{
						height: 400, 
						backgroundImage: `url(${imageUrlFor(buildImageObj(props)).width(1200).url()})`, 
						backgroundSize: 'cover', 
						backgroundRepeat: 'no-repeat', 
						backgroundPosition: 'center center'
					}}/> :
			<figure>
				{props.asset && (
					<img
						src={imageUrlFor(buildImageObj(props))
							.width(1200)
							.url()}
						alt={props.alt}
					/>
				)}
				<figcaption className={"figure--image"}>{props.caption}</figcaption>
			</figure>
			}
		</Fragment>
  )
}

export default Figure
