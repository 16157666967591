import { Link } from 'gatsby'
import React from 'react'
import { buildImageObj, cn } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import VideoEmbed from '../video-embed/video-embed'
import BlockText from '../block-text'
import BlockContent from '../block-content'


// reactstrap components
import {
  Container,
  Row,
} from "reactstrap";

function VideoBanner (props) {
	const { pageBranding, mainVideo, _rawBody, _rawSubtitle, useVideo, mainImage, hideSubtitle, bannerId} = props;
  return (
    <Container className={'banner'} id={bannerId}>
      <Row>
        <div className={'overlay'} />
        <div className={'banner-child'}>
				{
					pageBranding && (
						<div className={'branding'}>
							<img
								src = {
									imageUrlFor(buildImageObj(pageBranding))
									.width(600)
									.url()}
							/>
						</div>
					)
				}
          {_rawSubtitle && !hideSubtitle && (<BlockContent blocks={_rawSubtitle || []} />)}
        </div>
        {
          useVideo && mainVideo ?
          <VideoEmbed { ...mainVideo}/>
          : null
        }
        {
          !useVideo && mainImage ?
          <div className={'background-image'} style={{
            backgroundImage: `url(${imageUrlFor(buildImageObj(mainImage)).width(1600).url()})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }} />
          : null
        }
      </Row>
    </Container>
   )
}

export default VideoBanner;
