import React from 'react'
import PropTypes from 'prop-types'
import getVideoId from 'get-video-id'


function buildFileUrl (source) {
  const regex = new RegExp(/\.mp4|\.ogg|\.webm/g);
  const obj = source.hasOwnProperty('asset') && (source.asset.hasOwnProperty('_ref') || source.asset.hasOwnProperty('_id')) ? {
    asset: { _ref: source.asset._ref || source.asset._id }
  } : {asset: { _ref: source }};
  obj.type = (regex).test(obj.asset._ref) ? (obj.asset._ref).match(regex)[0].replace('.', "") : false;

  if (!obj.type) return;
  return obj
}
export default class VideoEmbedPreviewUrl extends React.Component {
  static propTypes = {
    value: PropTypes.object
  }

  getEmbedCode(value, videoStyles) {
    const videoId = (value && value.url) ? getVideoId(value.url) : ''
		const iFrameStyles = {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
		}
    if (!videoId) {
      return <span />
    }

    switch (videoId.service) {
      case 'youtube': {
				return <iframe 
					style={{ ...iFrameStyles, ...(videoStyles ? videoStyles : {})}}
          src={`https://www.youtube.com/embed/${videoId.id}?rel=0`} 
          frameBorder="0" 
          width="400"
          height="300"
          webkitallowfullscreen
          mozallowfullscreen
          allowFullScreen />
      }

      case 'vimeo': {
        return (
          <iframe
						style={{ ...iFrameStyles, ...(videoStyles ? videoStyles : {})}}
            src={`https://player.vimeo.com/video/${videoId.id}`}
            width="400"
            height="300"
            frameBorder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowFullScreen
          />
        )
      }
      default: {
        return <span>Unsupported video service: {videoId.service}</span>
      }
    }
	}
	
  render() {
		const {value} = this.props;
		const styles = {
			minHeight: '2em',
			position: 'relative',
			paddingBottom: '56.25%', 
			paddingTop: 25,
			height: 0,
			marginTop: 25,
			marginBottom: 45
		};
		if(value.url) {
			// const file = buildFileUrl(value.url);
			return (
				<div style={{ ...styles, ...(this.props.style ? this.props.style : {})}}>
						{this.getEmbedCode(value, this.props.videoStyles)}
				</div>
			)
		} else {
			return <div>
				missing video url
			</div>
		}
  }
}