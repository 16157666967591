import React, { Fragment, useState } from 'react'
import NavBar from '../common/navbar/navbar'
import Footer from '../common/footer/footer'
// import styles from './layout.module.css'

const Layout = ({ children, companyInfo, siteTitle, siteLogo, siteRoutes }) => {
	const [showNav, setShowNav] = useState(false)
  function toggleNav (bool) {
		if(typeof bool === "boolean") {
			setShowNav(bool)
		} else {
			setShowNav(!showNav)
		}
  }
	return (
		<Fragment>
			<NavBar 
				siteTitle={siteTitle} 
				companyInfo={companyInfo} 
				toggleNav={toggleNav}
				showNav={showNav} 
				siteLogo={siteLogo} 
				routes={siteRoutes} 
			/>
			<div className={"section section-basic"} onClick={(evt) => {setShowNav(false)}}>{children}</div>
			<Footer companyInfo={companyInfo} siteLogo={siteLogo} siteRoutes={siteRoutes}/>
		</Fragment>
	)
}

export default Layout
